export const userTypes = [
  {
    key: 'seeker',
    label: 'Seeker',
  },
  {
    key: 'provider',
    label: 'Provider',
  },
];
